// src/ReportCommentsPage.js
import '../Style/ReportsCommentsPage.css'; // Ensure styles are set up properly
import React, { useState, useEffect, useCallback } from 'react';
import ChatLogCard from '../Components/chatLogCard';
import { supabase } from '../Components/supabaseClient';

function ReportCommentsPage({ activeClient, userName }) {
  const [unfixedReports, setUnfixedReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUnfixedReports = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('chatLogs')
        .select('name, id, description, date, visited_pages, report_comment, report_comment_fixed')
        .eq('name', activeClient)
        .neq('report_comment', '')
        .order('date', { ascending: false });

      if (error) throw error;

      if (data != null) {
        // Filter out unfixed comments (false or null values for `report_comment_fixed`)
        const unfixed = data.filter(log => log.report_comment_fixed === false || log.report_comment_fixed === null);
        setUnfixedReports(unfixed);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  }, [activeClient]);

  useEffect(() => {
    if (activeClient) {
      fetchUnfixedReports();
    }
  }, [activeClient, fetchUnfixedReports]);

  // Call this function to re-fetch the data when a chat log card is updated
  const handleUpdate = () => {
    setIsLoading(true);
    fetchUnfixedReports();
  };

  return (
    <div className="report-comments-page">
      <h3>Unfixed Reported Comments ({unfixedReports.length})</h3>
      <hr />
      {isLoading ? (
        <div className="loader" />
      ) : (
        <div>
          {unfixedReports.map((chatLog, index) => (
            <ChatLogCard key={index} chatLog={chatLog} userName={userName} onUpdate={handleUpdate} />
          ))}
        </div>
      )}
    </div>
  );
}

export default ReportCommentsPage;
