import React, { useEffect, useState, useCallback } from 'react';
import { Card, Form, Button, Alert, Badge, Tab, Tabs } from 'react-bootstrap';
import { SlPencil, SlCheck, SlPlus, SlTrash } from "react-icons/sl";
import { supabase } from '../Components/supabaseClient';
import { Parser } from "html-to-react";
import flags from 'emoji-flags';
import '../Style/DataPage.css';
const HtmlToReactParser = new Parser();

function DataPage({ activeClient }) {
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(null);
  const [editingTab, setEditingTab] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    topicData: '',
    topic: '',
    keywords: []
  });
  const [settingsData, setSettingsData] = useState([]);
  const [settingsFormData, setSettingsFormData] = useState({
    id: '',
    topicData: '',
    topic: ''
  });
  const [error, setError] = useState(null);
  const [activeSettingsTab, setActiveSettingsTab] = useState('general');
  const [selectedLang, setSelectedLang] = useState('en');

  const langToFlag = {
    en: flags.US.emoji,
    de: flags.DE.emoji,
    sv: flags.SE.emoji,
  };

  const fetchData = useCallback(async (tabKey) => {
    let tableName = tabKey === 'data' ? 'clientData' : 'clientSettings';
    try {
      const { data, error } = await supabase.from(tableName).select('*').eq('client', activeClient);
      if (error) throw error;

      const formattedData = data.map(item => ({
        ...item,
        keywords: item.keywords ? item.keywords.split(',') : []
      }));

      if (tabKey === 'data') {
        setData(formattedData);
      } else {
        setSettingsData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`Error fetching data: ${error.message}`);
    }
  }, [activeClient]);

  useEffect(() => {
    if (activeClient) {
      fetchData('data'); // Fetch default data on mount
    }
  }, [activeClient, fetchData]);

  const filteredSettingsData = settingsData.filter((item) => {
    if (activeSettingsTab === 'persona') {
      return item.topic === 'persona';
    } else if (activeSettingsTab === 'general') {
      return item.topic === 'general';
    } else if (activeSettingsTab.startsWith('welcomeMessage')) {
      return item.topic.startsWith('welcomeMessage');
    } else if (activeSettingsTab.startsWith('tiktok')) {
      return item.topic.startsWith('tiktok');
    }else if (activeSettingsTab.startsWith('welcomeVideo')) {
      return item.topic.startsWith('welcomeVideo');
    }
    else {
      return true; // Default to show all items if no specific tab is selected
    }
  });

  const handleTabSelect = (key) => {
    fetchData(key);
    setEditing(null); // Reset editing state when switching tabs
    setEditingTab(null);
    if (key === 'settings') {
      setActiveSettingsTab('general'); // Default to 'general' tab in settings
    } else {
      setActiveSettingsTab(key);
    }
    setFormData({
      id: '',
      topicData: '',
      topic: '',
      keywords: []
    }); // Clear form data when switching tabs
    setSettingsFormData({
      id: '',
      topicData: '',
      topic: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (editingTab === 'settings') {
      setSettingsFormData({ ...settingsFormData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleEdit = (item, tabKey) => {
    setEditing(item.id);
    setEditingTab(tabKey);
    if (tabKey === 'settings') {
      setSettingsFormData({
        id: item.id,
        topicData: item.topicData,
        topic: item.topic
      });
    } else {
      setFormData({
        id: item.id,
        topicData: item.topicData,
        topic: item.topic,
        keywords: item.keywords || []
      });
    }
  };

  const handleSave = async (tabKey) => {
    const tableName = tabKey === 'data' ? 'clientData' : 'clientSettings';
    try {
      const { id, topicData, topic, keywords } = tabKey === 'data' ? formData : settingsFormData;
      const newData = {
        client: activeClient,
        topicData,
        topic,
      };
      if (tabKey === 'data') {
        newData.keywords = keywords ? keywords.join(',') : '';
      }
      if (editing === 'new') {
        const { error } = await supabase
          .from(tableName)
          .insert(newData);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from(tableName)
          .update(newData)
          .eq('id', id);
        if (error) throw error;
      }
      fetchData(tabKey);
      setEditing(null);
      setEditingTab(null);
    } catch (error) {
      console.error('Error saving data:', error.message);
      setError(`Error saving data: ${error.message}`);
    }
  };

  const handleDelete = async (id, tabKey) => {
    const tableName = tabKey === 'data' ? 'clientData' : 'clientSettings';
    try {
      const { error } = await supabase
        .from(tableName)
        .delete()
        .eq('id', id);
      if (error) throw error;
      fetchData(tabKey);
      setEditing(null);
      setEditingTab(null);
    } catch (error) {
      console.error('Error deleting data:', error.message);
      setError(`Error deleting data: ${error.message}`);
    }
  };

  const handleAddData = (tabKey) => {
    setEditing('new');
    setEditingTab(tabKey);
    if (tabKey === 'settings') {
      setSettingsFormData({
        id: '',
        topicData: '',
        topic: activeSettingsTab
      });
    } else {
      setFormData({
        id: '',
        topicData: '',
        topic: '',
        keywords: []
      });
    }
  };

  const handleRemoveKeyword = (index) => {
    const newKeywords = [...formData.keywords];
    newKeywords.splice(index, 1);
    setFormData({ ...formData, keywords: newKeywords });
  };

  const handleAddKeyword = () => {
    const newKeyword = prompt('Enter new keyword:');
    if (newKeyword && !formData.keywords.includes(newKeyword)) {
      const newKeywords = [...formData.keywords, newKeyword];
      setFormData({ ...formData, keywords: newKeywords });
    }
  };

  const handleSelectLang = (langCode) => {
    setSelectedLang(langCode);
    setSettingsFormData(prev => ({
      ...prev,
      topic: 'welcomeMessage_' + langCode,
    }));
  };

  function addLineBreaks(topicData) {
    // Split the topicData into an array of meanings based on a period delimiter
    let meanings = topicData.split('.');
  
    // Initialize an empty array to store the formatted meanings
    let formattedMeanings = [];
  
    // Loop through the meanings and add a <br><br> after every four meanings
    for (let i = 0; i < meanings.length; i++) {
      // Trim whitespace and add the current meaning to the formatted array if it's not empty
      if (meanings[i].trim()) {
        formattedMeanings.push(meanings[i].trim() + '.');
      }
  
      // Check if we are at an index where we need to add a line break
      if ((i + 1) % 4 === 0 && i !== 0) {
        formattedMeanings.push('<br><br>');
      }
    }
  
    // Join the formatted meanings back into a single string
    return formattedMeanings.join(' ').replace(/\.\s*<br>/g, '.<br>');
  }
  

  const renderCard = (item, tabKey) => (
    <Card key={item.id} className="mb-3">
      <Card.Body>
        {editing === item.id ? (
          <>
            {tabKey !== 'settings' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Topic</Form.Label>
                  <Form.Control
                    type="text"
                    name="topic"
                    value={tabKey === 'settings' ? settingsFormData.topic : formData.topic}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Keywords</Form.Label>
                  <div>
                    {formData.keywords.map((kw, index) => (
                      <Badge key={index} bg="secondary" className="me-1" onClick={() => handleRemoveKeyword(index)}>
                        {kw} <span style={{ cursor: 'pointer' }}>×</span>
                      </Badge>
                    ))}
                    <Button onClick={handleAddKeyword}><SlPlus /></Button>
                  </div>
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Topic Data</Form.Label>
              <Form.Control
                as="textarea"
                rows={14}
                name="topicData"
                value={tabKey === 'settings' ? settingsFormData.topicData : formData.topicData}
                onChange={handleChange}
              />
            </Form.Group>
            <footer>
              <Button variant="primary" onClick={() => handleSave(tabKey)}>
                <SlCheck /> Save
              </Button>
              <Button variant="danger" onClick={() => handleDelete(item.id, tabKey)}>
                <SlTrash /> Delete
              </Button>
            </footer>
          </>
        ) : (
          <>
            <Card.Header>
              <Card.Title>{item.topic}</Card.Title>
              <Button variant="secondary" onClick={() => handleEdit(item, tabKey)}>
                <SlPencil /> Edit
              </Button>
            </Card.Header>
            {tabKey !== 'settings' && (
              <Card.Text>
                <strong>Keywords: </strong>
                {item.keywords.join(', ')}
              </Card.Text>
            )}
            <Card.Text>
              {HtmlToReactParser.parse(addLineBreaks(item.topicData.replace('. ', '')))}
            </Card.Text>
          </>
        )}
      </Card.Body>
    </Card>
  );

  const renderNewCard = (tabKey) => (
    <Card className="mb-3">
      <Card.Body>
        {tabKey !== 'settings' && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Topic</Form.Label>
              <Form.Control
                type="text"
                name="topic"
                value={tabKey === 'settings' ? settingsFormData.topic : formData.topic}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Keywords</Form.Label>
              <div>
                {formData.keywords.map((kw, index) => (
                  <Badge key={index} bg="secondary" className="me-1" onClick={() => handleRemoveKeyword(index)}>
                    {kw} <span style={{ cursor: 'pointer' }}>×</span>
                  </Badge>
                ))}
                <Button onClick={handleAddKeyword}><SlPlus /></Button>
              </div>
            </Form.Group>
          </>
        )}
        {editingTab === 'settings' && activeSettingsTab.startsWith('welcomeMessage') && (
          <Form.Group className="mb-3">
            <Form.Label>Select Language</Form.Label>
            <Form.Control as="select" value={selectedLang} onChange={(e) => handleSelectLang(e.target.value)}>
              {Object.entries(langToFlag).map(([lang, emoji]) => (
                <option key={lang} value={lang}>{emoji} {lang.toUpperCase()}</option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
        {editingTab === 'settings' && activeSettingsTab.startsWith('persona') && (
          <Form.Group className="mb-3">
            <Form.Control as="select" value="persona" disabled>
              <option value="persona">Persona</option>
            </Form.Control>
          </Form.Group>
        )}
        {editingTab === 'settings' && activeSettingsTab.startsWith('general') && (
          <Form.Group className="mb-3">
            <Form.Control as="select" value="general" disabled>
              <option value="general">General</option>
            </Form.Control>
          </Form.Group>
        )}
        {editingTab === 'settings' && activeSettingsTab.startsWith('tiktok') && (
          <Form.Group className="mb-3">
            <Form.Label>Keyword</Form.Label>
            <Form.Control
              type="text"
              name="topic"
              value={settingsFormData.topic}
              onChange={handleChange}
            />
          </Form.Group>
        )}
            {editingTab === 'settings' && activeSettingsTab.startsWith('welcomeVideo') && (
          <Form.Group className="mb-3">
            <Form.Label>Video URL</Form.Label>
            <Form.Control
              type="text"
              name="topic"
              value={settingsFormData.topic}
              onChange={handleChange}
            />
          </Form.Group>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Data</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="topicData"
            value={tabKey === 'settings' ? settingsFormData.topicData : formData.topicData}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" onClick={() => handleSave(tabKey)}>
          <SlCheck /> Save
        </Button>
      </Card.Body>
    </Card>
  );

  return (
    <div className='dataPageContainer'>
      <Tabs defaultActiveKey="data" className="mb-3" onSelect={handleTabSelect}>
        <Tab eventKey="data" title="Data">
          {error && <Alert variant="danger">{error}</Alert>}
          <div className="card-container">
            {data.map((item) => renderCard(item, 'data'))}
            {editing === 'new' && editingTab === 'data' && renderNewCard('data')}
            <Button variant="primary" onClick={() => handleAddData('data')}>Add Data</Button>
          </div>
        </Tab>
        <Tab eventKey="settings" title="Settings">
          {error && <Alert variant="danger">{error}</Alert>}
          <Tabs className="mb-3" activeKey={activeSettingsTab} onSelect={handleTabSelect}>
            <Tab eventKey="general" title="General"></Tab>
            <Tab eventKey="persona" title="Persona"></Tab>
            <Tab eventKey="welcomeMessage" title="Welcome Message"></Tab>
            <Tab eventKey="tiktok" title="TikTok"></Tab>
            <Tab eventKey="welcomeVideo" title="Welcome Video"></Tab>
          </Tabs>
          <div className="card-container">
            {filteredSettingsData.map((item) => renderCard(item, 'settings'))}
            {editing === 'new' && editingTab === 'settings' && renderNewCard('settings')}
            <Button variant="primary" onClick={() => handleAddData('settings')}>Add Data</Button>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default DataPage;
