import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import ChatLogPage from './Pages/ChatLogPage';
import DataPage from './Pages/DataPage';
import HomePage from './Pages/HomePage';
import SupportPage from './Pages/SupportPage';
import SettingsPage from './Pages/SettingsPage';
import ReportCommentsPage from './Pages/ReportsCommentsPage';
import ResetPassword from './Pages/ResetPassword';
import { supabase } from './Components/supabaseClient';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { SlGrid, SlBubble, SlLayers, SlLogout, SlEnvolopeLetter, SlExclamation, SlMenu, SlClose, SlSettings } from "react-icons/sl";
import './App.css';
import './Style/Sidebar.css';

const hubbieLogo = require('./assets/logo.png');

function App() {
  const [session, setSession] = useState(null);
  const [userName, setUserName] = useState('');
  const [activeClient, setActiveClient] = useState('idaWargBeauty');
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session && session.user) {
        setUserName(session.user.email);
      }
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session && session.user) {
        setUserName(session.user.email);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    let client;
    if (userName.includes('hubbie.ai')) {
      client = 'idaWargBeauty';
    } else {
      client = extractClientFromUsername(userName);
    }
    setActiveClient(client);
  }, [userName]);

    // Fetch clients from Supabase
    useEffect(() => {
      const fetchClients = async () => {
        const { data, error } = await supabase
          .from('clients')   // The table you're fetching from
          .select('name');  // The column to retrieve
  
        if (error) {
          console.error("Error fetching clients:", error);
        } else {
          setClients(data); // Update state with the fetched clients
        }
      };
  
      fetchClients();
    }, []);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    window.location.reload();
  };

  const handleClientChange = (activeClient) => {
    setActiveClient(activeClient);
  };

  function extractClientFromUsername(username) {
    const parts = username.split('@');
    if (parts.length > 1) {
      let client = parts[1].split('.')[0];
      // REMOVE THIS LATER
      if (client.includes('idawargbeauty')) {
        return 'idaWargBeauty';
      }
      return client;
    }
    return '';
  }

  function displayName(email) {
    let userName = email.split('@')[0];
    if (userName.includes('.')) {
      userName = userName.split('.')[0];
    }
    userName = userName.charAt(0).toUpperCase() + userName.slice(1);
    return `Hello, ${userName} 👋`;
  }

  function displayFirstLetter(email) {
    let userName = email.split('@')[0];
    let firstLetter = userName.charAt(0).toUpperCase();
    return `${firstLetter}`;
  }

  function LoginScreen() {
    return (
      <div className='login-screen'>
        <img alt="Hubbie AI logo" className="logo" src={hubbieLogo} />
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          theme="dark"
          providers={[]}
        />
      </div>
    );
  }

  return (
    <Router>
      {!session ? (
        <div className='login'>
          <LoginScreen />
        </div>
      ) : (
        <div className="App">
          <button className="menu-toggle" onClick={toggleSidebar}>
            {isOpen ? <SlClose /> : <SlMenu />}
          </button>
          <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <header>
              <div className="sidebar-logo"></div>
              <NavLink to="/" className="sidebar-link" onClick={toggleSidebar}><SlGrid /></NavLink>
              <NavLink to="/chat-log" className="sidebar-link" onClick={toggleSidebar}><SlBubble /></NavLink>
              {userName.includes('hubbie.ai') && (
                <>
                  <NavLink to="/data" className="sidebar-link" onClick={toggleSidebar}><SlLayers /></NavLink>
                  <NavLink to="/supportPage" className="sidebar-link" onClick={toggleSidebar}><SlEnvolopeLetter /></NavLink>
                  <NavLink to="/ReportsCommentsPage" className="sidebar-link" onClick={toggleSidebar}><SlExclamation /></NavLink>
                  <NavLink to="/settings" className="sidebar-link" onClick={toggleSidebar}><SlSettings /></NavLink>
                </>
              )}
            </header>
            <footer>
              <button className='logout-btn' onClick={handleLogout}><SlLogout /></button>
            </footer>
          </div>
          <header className="App-header">
            <div className="userHeader">
              <div className="userHeader__profile">{displayFirstLetter(userName)}</div>
              <div className="userHeader__profile--text">
                <h3>{displayName(userName)}</h3>
                <span>Monthly Premium User</span>
              </div>
              {userName.includes('hubbie.ai') && (
                <>
                  <Form.Control as="select" className="custom-select" onChange={(e) => handleClientChange(e.target.value)} value={activeClient} disabled={!userName.includes('hubbie.ai')}>
                    {clients.map((client, index) => (
                      <option key={index} value={client.name}>{client.name}</option>
                    ))}
                  </Form.Control>
                  <hr />
                </>
              )}
            </div>
            <Routes>
              <Route path="/" element={<HomePage activeClient={activeClient} />} />
              <Route path="/chat-log" element={<ChatLogPage activeClient={activeClient} userName={userName} />} />
              {userName.includes('hubbie.ai') && (
                <>
                  <Route path="/data" element={<DataPage activeClient={activeClient} />} />
                  <Route path="/supportPage" element={<SupportPage activeClient={activeClient} userName={userName} />} />
                  <Route path="/ReportsCommentsPage" element={<ReportCommentsPage activeClient={activeClient} userName={userName} />} />
                  <Route path="/settings" element={<SettingsPage />} />
                </>
              )}
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          </header>
        </div>
      )}
    </Router>
  );
}

export default App;
