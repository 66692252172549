import React, { useState } from 'react';
import { supabase } from '../Components/supabaseClient';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleResetPassword = async (event) => {
        event.preventDefault();
        const { error } = await supabase.auth.resetPasswordForEmail(email);

        if (error) {
            setMessage(`Error: ${error.message}`);
        } else {
            setMessage('Check your email for the reset password link!');
        }
    };

    return (
        <div>
            <h1>Reset Your Password</h1>
            <form onSubmit={handleResetPassword}>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Reset Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default ResetPassword;
