import '../Style/SupportPage.css';
import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Alert, Form, Col, Container, Row, ListGroup, Dropdown, DropdownButton, InputGroup, FormControl } from 'react-bootstrap';
import { supabase } from '../Components/supabaseClient';
import { Parser } from "html-to-react";
import { SlPaperPlane, SlShuffle, SlMagnifier } from "react-icons/sl";
const HtmlToReactParser = new Parser();

function SupportPage({ activeClient, userName }) {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [answers, setAnswers] = useState({}); // To store the answers for each task
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedTask, setSelectedTask] = useState([]);
  const [sortCriterion, setSortCriterion] = useState();
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' for ascending, 'desc' for descending
  const [searchTerm, setSearchTerm] = useState('');


  // Fetch tasks data from Supabase or API
  const fetchTasks = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.from('supportTasks').select('*').eq('client', activeClient); // Modify table name and columns as needed
      if (error) throw error;
      setTasks(data);
      setSelectedTask(data[0]);
      if(data){
        setSortCriterion('created_at');
      }
    } catch (error) {
      setError('Failed to fetch tasks');
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  }, [activeClient]);

  useEffect(() => {
    if (activeClient) {
      fetchTasks(); // Fetch default data on mount
    }
  }, [activeClient, fetchTasks]);


  // Update task status or priority
  const handleUpdateTask = async (taskId, updatedData) => {
    try {
      const { error } = await supabase.from('tasks').update(updatedData).eq('id', taskId);
      if (error) throw error;
      fetchTasks(); // Re-fetch tasks after updating
    } catch (error) {
      console.error('Error updating task:', error);
      alert('Failed to update task.');
    }
  };

  // Handle input change for the answer
  const handleInputChange = (taskId, value) => {
    setAnswers({
      ...answers,
      [taskId]: value,
    });
  };

  const sortTasks = () => {
    return [...tasks].sort((a, b) => {
      if (a[sortCriterion] < b[sortCriterion]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortCriterion] > b[sortCriterion]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSortChange = (criterion) => {
    setSortCriterion(criterion);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTasks = () => {
    return sortTasks().filter(task =>
      task.user_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.support_id.toString().includes(searchTerm)
    );
  };

  // Send an email to the user
  const handleSendEmail = async (task) => {
    const url = process.env.REACT_APP_NODE_SERVER + "/send-email";

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          clientEmail: task.user_email,
          email: userName,
          name: task.user_name,
          ordernumber: task.support_id,
          message: `\n\n${answers[task.id] || ''}\n\n-------------\n\n${task.description}\n\n`,
        }),
      });

      if (!response.ok) throw new Error('Email sending failed');
      setSuccessMessage('Email sent successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
      // Reset the answer input field for this task
      setAnswers(prevAnswers => ({ ...prevAnswers, [task.id]: '' }));
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email.');
    }
  };

  return (
    <div>
      <h2>Customer Support</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="supportPageContainer">
          <Container>
            <Row>
              <InputGroup className="mb-2 search">
                <InputGroup.Text><SlMagnifier /></InputGroup.Text>
                <FormControl
                  placeholder="Email or Support ID"
                  onChange={handleSearchTermChange}
                />
              </InputGroup>
              <div className='list-sort-container'>
                <DropdownButton title="Sort by" className="mb-2 dropdownSort">
                  <Dropdown.Item onClick={() => handleSortChange('created_at')}>Date</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortChange('user_email')}>Email</Dropdown.Item>
                </DropdownButton>
                <Button onClick={toggleSortOrder} className="mb-2 toggleSort">
                  {sortOrder === 'asc' ? 'Oldest' : 'Newest'} <SlShuffle />
                </Button>
              </div>
              {tasks.length === 0 ? (
                <p>No support tasks found</p>
              ) : (
                <ListGroup>
                  {filteredTasks().map(task => (
                    <ListGroup.Item
                      key={task.id}
                      action onClick={() => setSelectedTask(task)}
                      active={selectedTask && selectedTask.id === task.id}
                    >
                      {task.user_email}<br />
                      {task.created_at.slice(0, 10)}<br />
                      {task.chatlog ? ' (Chat Available)' : ' (No Chat)'}<br />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Row>
          </Container>
          {selectedTask && tasks.length > 0 && (
            <Container>
              <Card key={selectedTask.id}>
                <Card.Header>
                  <div className='info'>
                    <Card.Title>{selectedTask.created_at.replace("T", " ").slice(0, 16)}</Card.Title>
                    <Card.Text className='code'>#{selectedTask.support_id} | {selectedTask.user_name} ({selectedTask.user_email})</Card.Text>
                  </div>
                  <div className='form-actions'>
                    <Col className="mr-8">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        value={selectedTask.status}
                        onChange={(e) => handleUpdateTask(selectedTask.id, { status: e.target.value })}
                      >
                        <option value="open">Open</option>
                        <option value="in_progress">In Progress</option>
                        <option value="closed">Closed</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Priority</Form.Label>
                      <Form.Select
                        value={selectedTask.priority}
                        onChange={(e) => handleUpdateTask(selectedTask.id, { priority: e.target.value })}
                      >
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Select>
                    </Col>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className='message-container'>
                    <Card.Text className='email-container'>
                      <span className='sender'>{selectedTask.user_email}</span><br></br><br></br>
                      {selectedTask.description}
                    </Card.Text>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Type your answer here..."
                      value={answers[selectedTask.id] || ''}
                      onChange={(e) => handleInputChange(selectedTask.id, e.target.value)}
                      className="mt-2"
                    />
                    <Button variant="btn-md" onClick={() => handleSendEmail(selectedTask)} className="mt-2"><SlPaperPlane /> Send</Button>
                  </div>
                  <Card.Text className='chatlog-container'>
                    {selectedTask.chatlog ? (
                      HtmlToReactParser.parse(selectedTask.chatlog.replaceAll(activeClient + ":", "<br><br> <span className='bot-msg'>Bot:</span>").replaceAll("user:", "<br><br> <span className='user-msg'>User:</span>").replaceAll(",--", ""))
                    ) : (
                      <span>No chatlog found</span>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Container>
          )}
        </div>
      )}
    </div>
  );
}

export default SupportPage;
