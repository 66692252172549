import React, { useState, useEffect } from 'react';
import { Card, Modal, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Parser } from "html-to-react";
import { FaRegStickyNote, FaCheckCircle } from 'react-icons/fa';
import { SlCheck } from "react-icons/sl";
import { supabase } from './supabaseClient';

const HtmlToReactParser = new Parser();

function ChatLogCard(props) {
    const onUpdate = props.onUpdate;
    const chatLog = props.chatLog;
    const userName = props.userName; // Assume userName is passed as a prop
    const [showReportModal, setShowReportModal] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
    const [reportText, setReportText] = useState("");
    const [initialReport, setInitialReport] = useState("");
    const [isFixed, setIsFixed] = useState(chatLog.report_comment_fixed); // Initialize with the current fixed status

    useEffect(() => {
        // Initialize the initial report comment when the component mounts or chatLog changes
        setInitialReport(chatLog.report_comment || "");
    }, [chatLog.report_comment, isFixed]);

    const handleReportChange = (event) => {
        setReportText(event.target.value);
    };

    const handleReportSubmit = async (event) => {
        event.preventDefault();
        const currentDate = new Date();  // Gets the current date and time
        const formattedTime = currentDate.toLocaleString('sv-SE', {
            timeZone: 'Europe/Stockholm',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // Use 'false' for 24-hour format typical in Sweden
        });

        const updatedReport = initialReport + (initialReport ? "\n" : "") + `${formattedTime} (${userName}) <br> ${reportText} <br><br>`;

        const { data, error } = await supabase
            .from('chatLogs')
            .update({ report_comment: updatedReport })
            .match({ id: chatLog.id });

        if (error) {
            console.error('Error updating report:', error.message);
        } else {
            console.log('Report updated successfully:', data);
            setShowReportModal(false);
            setReportText("");
            setInitialReport(updatedReport); // Update the initial report with the new text
            onUpdate(); // Notify the parent component
        }
    };

    const toggleFixedStatus = async () => {
        const newFixedStatus = !isFixed;
        const { data, error } = await supabase
            .from('chatLogs')
            .update({ report_comment_fixed: newFixedStatus })
            .match({ id: chatLog.id });

        if (error) {
            console.error('Error updating fixed status:', error.message);
        } else {
            console.log('Status updated successfully:', data);
            setIsFixed(newFixedStatus);
            onUpdate(); // Notify the parent component
        }
    };

    const handleDelete = async () => {
        // Check if the user has permission to delete
        if (!userName.includes('hubbie.ai')) {
            alert('You do not have permission to delete this item.');
            setShowDeleteModal(false);
            return;
        }

        const { data, error } = await supabase
            .from('chatLogs')
            .delete()
            .match({ id: chatLog.id });

        if (error) {
            console.error('Error deleting chat log:', error.message);
        } else {
            console.log('Chat log deleted successfully:', data);
            setShowDeleteModal(false);
            onUpdate(); // Notify the parent component to refresh the list
        }
    };

    return (
        <Card style={{ marginBottom: "2rem" }}>
            <Card.Body>
                <div className='card__header'>
                    <Card.Title>{chatLog.date.replace("T", " ").replace("+00:00", "")}<span className='small'> id: {chatLog.id}</span></Card.Title>
                    <div>
                        {chatLog.report_comment && (
                            <span>
                                <FaCheckCircle
                                    style={{
                                        marginRight: 5,
                                        cursor: userName.includes('hubbie.ai') ? 'pointer' : 'not-allowed',
                                        color: isFixed ? 'green' : 'grey'
                                    }}
                                    onClick={userName.includes('hubbie.ai') ? toggleFixedStatus : undefined}
                                />
                            </span>
                        )}
                        {chatLog.report_comment && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>Click to view comment</Tooltip>}
                            >
                                <span>
                                    <FaRegStickyNote
                                        style={{ marginRight: 10, cursor: 'pointer' }}
                                        onClick={() => setShowCommentModal(true)}
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                        <button onClick={() => setShowReportModal(true)}>Report</button>
                        {userName.includes('hubbie.ai') && (
                            <button onClick={() => setShowDeleteModal(true)} style={{ marginLeft: 10 }}>Delete</button>
                        )}
                    </div>
                </div>
                <Card.Text>
                    {HtmlToReactParser.parse(chatLog.description.replaceAll(chatLog.name + ":", "<br><br> <span className='bot-msg'>Bot:</span>").replaceAll("user:", "<br><br> <span className='user-msg'>User:</span>").replaceAll(",--", ""))}
                </Card.Text>
            </Card.Body>

            {/* Modals for reporting, displaying comments, and delete confirmation */}
            <Modal show={showReportModal} onHide={() => setShowReportModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Report an Issue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleReportSubmit}>
                        <Form.Group className="mb-3" controlId="reportText">
                            <Form.Label>Describe the issue</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={reportText}
                                onChange={handleReportChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit Report
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showCommentModal} onHide={() => setShowCommentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Report Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {HtmlToReactParser.parse(chatLog.report_comment)}

                    {isFixed && (
                        <>
                            <hr />
                            <span className='comment-is-fixed'> <SlCheck /> Chat bot updated.</span>
                        </>
                    )}
                </Modal.Body>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this chat log?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
}

export default ChatLogCard;
