// src/ChatLogPage.js
import '../Style/ChatLog.css';
import React, { useState, useEffect, useCallback } from 'react';
import ChatLogCard from '../Components/chatLogCard';
import { supabase } from '../Components/supabaseClient';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function ChatLogPage({ activeClient, userName }) {
  const [chatLogs, setChatLogs] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0)); // default start date
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 999)); // default end date
  const [isLoading, setIsLoading] = useState(true);
  const [attempted30Days, setAttempted30Days] = useState(false); // New state to prevent infinite loop

  const fetchFilteredData = useCallback(async () => {
    try {
      setIsLoading(true); // Set loading to true when fetching starts
      // Ensure startDate and endDate are Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Convert to ISO strings in UTC
      const formattedStartDate = start.toISOString();
      const formattedEndDate = end.toISOString();

      const { data, error } = await supabase
        .from("chatLogs")
        .select('name, id, description, date, visited_pages, report_comment, report_comment_fixed')
        .eq('name', activeClient)
        .gte('date', formattedStartDate)
        .lte('date', formattedEndDate)
        .order('date', { ascending: false });

      if (error) throw error;

      if (data != null) {
        setChatLogs(data);
        setIsLoading(false);

        // If chatLogs are empty and we haven't already attempted last 30 days, set date range to last 30 days
        if (data.length === 0 && !attempted30Days) {
          setAttempted30Days(true);
          setLast30Days();
          // No need to call fetchFilteredData here; useEffect will handle it
        }
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  }, [startDate, endDate, activeClient, attempted30Days]);

  useEffect(() => {
    if (activeClient && startDate && endDate) {
      console.log('Fetching data for:', activeClient, startDate, endDate);
      fetchFilteredData();
    }
  }, [activeClient, startDate, endDate, fetchFilteredData]);

  // Function to handle the date range change
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setAttempted30Days(false); // Reset the flag when date range changes
  };

  const setToday = () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    setStartDate(todayStart);
    setEndDate(todayEnd);
    setAttempted30Days(false); // Reset the flag
  };

  const setYesterday = () => {
    const yesterdayStart = new Date();
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0);

    const yesterdayEnd = new Date();
    yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
    yesterdayEnd.setHours(23, 59, 59, 999);

    setStartDate(yesterdayStart);
    setEndDate(yesterdayEnd);
    setAttempted30Days(false); // Reset the flag
  };

  const setLast7Days = () => {
    const last7DaysStart = new Date();
    last7DaysStart.setDate(last7DaysStart.getDate() - 7);
    last7DaysStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    setStartDate(last7DaysStart);
    setEndDate(todayEnd);
    setAttempted30Days(false); // Reset the flag
  };

  // New function for "Last 30 Days"
  const setLast30Days = () => {
    const last30DaysStart = new Date();
    last30DaysStart.setDate(last30DaysStart.getDate() - 30);
    last30DaysStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    setStartDate(last30DaysStart);
    setEndDate(todayEnd);
    // No need to reset attempted30Days here since we just set it to true
  };

  // Handles children's database updates like comment report in chatLogCard
  const handleUpdate = () => {
    fetchFilteredData();
  };

  return (
    <div>
      <div className='datepicker-group'>
        <div className='datepicker-input'>
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            isClearable={true}
            className='custom-select-input'
          />
          <button className='custom-select-btn' onClick={fetchFilteredData}>FILTER</button>
        </div>
        <div className='quick-button-grp'>
          <button onClick={setToday}>Today</button>
          <button onClick={setYesterday}>Yesterday</button>
          <button onClick={setLast7Days}>Last 7 Days</button>
          <button onClick={setLast30Days}>Last 30 Days</button>
        </div>
      </div>
      <div className="chatLogContainer">
        <h3>Chat Log ({chatLogs.length})</h3>
        <hr />
        {isLoading ? (
          <div className="loader" /> // Show loading spinner when data is loading
        ) : (
          chatLogs.length === 0 ? (
            <h2 className='empty-message'>No chat logs available.</h2>
          ) : (
            chatLogs.map((chatLog, index) => (
              <Col key={index}>
                <ChatLogCard chatLog={chatLog} userName={userName} onUpdate={handleUpdate} />
              </Col>
            ))
          )
        )}
      </div>
    </div>
  );
}

export default ChatLogPage;
