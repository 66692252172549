// stopWords.js
// English + Swedish stop words (add/remove as needed)
export const stopWords = new Set([
  // English
  'the', 'and', 'or', 'but', 'if', 'when', 'then', 'this', 'that', 'those',
  'these', 'for', 'with', 'what', 'where', 'how', 'why', 'can', 'should',
  'would', 'could', 'from', 'there', 'their', 'in', 'out', 'on', 'off',
  'of', 'a', 'an', 'it', 'is', 'to', 'at', 'as', 'are', 'be', 'by', 'do', 'so',
  'not', 'no', 'yes', 'your', 'you', 'we', 'our', 'ours', 'us', 'them', 'they',

  // Swedish common stop words
  'och', 'att', 'i', 'på', 'som', 'är', 'av', 'med', 'en', 'ett', 'till', 'den', 'det',
  'där', 'har', 'du', 'vi', 'ni', 'de', 'dem', 'er', 'mig', 'dig', 'honom', 'henne',
  'oss', 'dom', 'under', 'över', 'när', 'för', 'eller', 'men', 'om', 'jag', 'hon',
  'han', 'här', 'var', 'från', 'då', 'man', 'sig', 'sin', 'sina', 'sitt', 'idawargbeauty',
  'kan', 'user', 'href', 'hur', 'hej', 'hjälpa', 'hubbieai', 'rel', 'chatbot', 'order', 'target', 
  '_blank', 'noopener', 'noreferrer', 'https', 'products', 'hello help', 'fler frågor', 'fler frågor',
  'tveka inte', 'kollegor info', 'fler', 'tveka', 'kollegor','frågor', 'inte', 'info', 'försök igen',
  'försök', 'igen','kontakta', 'gärna', 'packad borde', 'packad', 'borde'
]);