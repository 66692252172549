import '../Style/HomePage.css';
import React, { useState, useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { supabase } from '../Components/supabaseClient';
import ChatPerHourBarChart from '../Components/ChatPerHourBarChart';
import DatePicker from 'react-datepicker';
import { stopWords } from '../Utils/stopWords';
import 'react-datepicker/dist/react-datepicker.css';
import { getTopBigrams, sendBigramsToOpenAI } from '../Utils/bigramsService';
import { BsStars } from "react-icons/bs";

function HomePage({ activeClient }) {
  const [todaysChatAmount, setTodaysChatAmount] = useState(0);
  const [todaysHrefCount, setTodaysHrefCount] = useState(0);
  const [todaysErrorCount, setTodaysErrorCount] = useState(0);
  const [todaysSupportMailCount, setTodaysSupportMailCount] = useState(0);
  const [allHrefs, setAllHrefs] = useState([]);
  const [outsideWorkingHoursCount, setOutsideWorkingHoursCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isInsightsLoading, setIsInsightsLoading] = useState(false);

  // Store data here so we can analyze it on-demand
  const [chatData, setChatData] = useState([]);

  // For displaying OpenAI result
  const [openAIResult, setOpenAIResult] = useState(null);

  // New state for top 10 products
  const [topProducts, setTopProducts] = useState([]);

  const [startDate, setStartDate] = useState(() => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  });
  
  const [endDate, setEndDate] = useState(() => {
    const d = new Date();
    d.setHours(23, 59, 59, 999);
    return d;
  });
  
  const [attempted30Days, setAttempted30Days] = useState(false);

  /**
   * Wrap groupProductLinks in useCallback to ensure
   * it has a stable identity when used by processHrefs.
   */
  const groupProductLinks = useCallback((links) => {
    const productArray = [];

    links.forEach(link => {
      // Check if the link contains '/products/'
      const index = link.indexOf('/products/');
      if (index !== -1) {
        const normalizedLink = link.substring(index).trim();
        // Check if the link already exists in productArray
        const existingProduct = productArray.find(item => item.link === normalizedLink);
        if (existingProduct) {
          existingProduct.count += 1;
        } else {
          productArray.push({ link: normalizedLink, count: 1 });
        }
      }
    });

    // Sort the array by count in descending order and take the top 10
    const top10 = productArray.sort((a, b) => b.count - a.count).slice(0, 10);
    setTopProducts(top10);
    return productArray;
  }, []);

  /**
   * Wrap processHrefs in useCallback so it won't change identity between renders,
   * and include groupProductLinks in the dependency array if we call it here.
   */
  const processHrefs = useCallback((data) => {
    const allLinks = [];
    const hrefCounts = data.reduce((acc, item) => {
      const hrefMatches = item.description.match(/href=["']([^"']*)["']/gi) || [];
      const mailtoCount = hrefMatches.filter(href => href.includes('mailto:')).length;
      const validHrefsCount = hrefMatches.filter(href => !href.includes('mailto:')).length;
      hrefMatches.forEach(href => {
        const urlMatch = href.match(/href=["']([^"']*)["']/i);
        if (urlMatch && urlMatch[1]) {
          let cleanUrl = urlMatch[1].split('?')[0];
          if (!allLinks.includes(cleanUrl)) {
            allLinks.push(cleanUrl);
          }
        }
      });
      acc.mailto += mailtoCount;
      acc.validHrefs += validHrefsCount;
      return acc;
    }, { mailto: 0, validHrefs: 0 });

    setTodaysHrefCount(hrefCounts.validHrefs);
    setTodaysSupportMailCount(hrefCounts.mailto);
    setAllHrefs(allLinks);

    // Process top 10 products
    groupProductLinks(allLinks);
  }, [groupProductLinks]);

  /**
   * Main data fetch. We store the raw data in `chatData`,
   * so we can analyze it later on-demand.
   */
  const fetchFilteredData = useCallback(async () => {
    setIsLoading(true);
    try {
      const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in ms
      const start = new Date(startDate);
      const end = new Date(endDate);

      const formattedStartDate = new Date(start.getTime() - tzoffset).toISOString();
      const formattedEndDate = new Date(end.getTime() - tzoffset).toISOString();

      const { data, error } = await supabase
        .from("chatLogs")
        .select('name, id, description, date, visited_pages')
        .eq('name', activeClient)
        .gte('date', formattedStartDate)
        .lte('date', formattedEndDate)
        .order('date', { ascending: false });

      if (error) throw error;

      if (data) {
        let outsideHours = 0;
        data.forEach(item => {
          const messageDate = new Date(item.date);
          const hours = messageDate.getHours();
          const minutes = messageDate.getMinutes();
          // Check if time is outside working hours (before 09:00 or after 16:30)
          if (hours < 9 || (hours === 16 && minutes > 30) || hours > 16) {
            outsideHours++;
          }
        });

        setOutsideWorkingHoursCount(outsideHours);
        setTodaysChatAmount(data.length);

        // Save fetched data in state, so we can analyze it later
        setChatData(data);

        // We still processHrefs because it’s part of the stats we need
        // (the user’s request might want to keep or remove this—adjust as needed)
        processHrefs(data);

        // REMOVE the call to getCommonWords here (so it doesn't auto-load)
        // getCommonWords(data);

        setTodaysErrorCount(data.filter(containsErrorKeyword).length);

        setIsLoading(false);

        // If chatLogs are empty and we haven't already attempted last 30 days, set date range to last 30 days
        if (data.length === 0 && !attempted30Days) {
          setAttempted30Days(true);
          setLast30Days();
        }
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  }, [
    activeClient,
    startDate,
    endDate,
    attempted30Days,
    processHrefs // include processHrefs to fix missing deps warning
  ]);

  useEffect(() => {
    if (activeClient) {
      fetchFilteredData();
    }
  }, [activeClient, startDate, endDate, fetchFilteredData]);

  // Utility function to handle the date range change from the picker
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const setToday = () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    setStartDate(todayStart);
    setEndDate(todayEnd);
  };

  const setYesterday = () => {
    const yesterdayStart = new Date();
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0);

    const yesterdayEnd = new Date();
    yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
    yesterdayEnd.setHours(23, 59, 59, 999);

    setStartDate(yesterdayStart);
    setEndDate(yesterdayEnd);
  };

  const setLast7Days = () => {
    const last7DaysStart = new Date();
    last7DaysStart.setDate(last7DaysStart.getDate() - 7);
    last7DaysStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    setStartDate(last7DaysStart);
    setEndDate(todayEnd);
  };

  const setLast30Days = () => {
    const last30DaysStart = new Date();
    last30DaysStart.setDate(last30DaysStart.getDate() - 30);
    last30DaysStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    setStartDate(last30DaysStart);
    setEndDate(todayEnd);
  };

  /**
   * We keep getCommonWords, but call it only on-demand via button.
   */
  function getCommonWords(data) {
    let allDescriptions = data.map(item => item.description || "").join(" ");
    const topBigrams = getTopBigrams(allDescriptions, stopWords);

    // Send the top bigrams to OpenAI
    sendBigramsToOpenAI(topBigrams).then(response => {
      // Extract the assistant's reply from the response
      if (response && response.choices && response.choices.length > 0) {
        const assistantMessage = response.choices[0].message.content;
        setOpenAIResult(assistantMessage);
      }
    });
  }

  function containsErrorKeyword(data) {
    const description = data.description || "";
    return (
      description.includes("🛠️") || 
      description.includes("error") || 
      description.includes(" 💫")
    );
  }

  const handleLoadInsights = async () => {
    if (!chatData.length) return; // no data, just return
  
    setIsInsightsLoading(true);
    try {
      // getCommonWords might be async, so we await it
      await getCommonWords(chatData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsInsightsLoading(false);
    }
  };

  return (
    <div className='homePage'>
      <h2>Dashboard</h2>
      <div className='datepicker-group' style={{ marginBottom: "2rem" }}>
        <div className='datepicker-input'>
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            isClearable={true}
            className='custom-select-input'
          />
          <button className='custom-select-btn' onClick={fetchFilteredData}>FILTER</button>
        </div>
        <div className='quick-button-grp'>
          <button onClick={setToday}>Today</button>
          <button onClick={setYesterday}>Yesterday</button>
          <button onClick={setLast7Days}>Last 7 Days</button>
          <button onClick={setLast30Days}>Last 30 Days</button>
        </div>
      </div>

      <div className='card__container'>
        <Card className="pink" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Chats in Selected Range</Card.Title>
            {isLoading ? (
              <div className="loader" />
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysChatAmount}</Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="yellow" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Recommendation Count</Card.Title>
            {isLoading ? (
              <div className="loader" />
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysHrefCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="green" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Support Mail Count</Card.Title>
            {isLoading ? (
              <div className="loader" />
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysSupportMailCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
      </div>

      <div className='card__container'>
        <Card className="beige" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>All Links</Card.Title>
            {isLoading ? (
              <div className="loader" />
            ) : (
              <Card.Text style={{ textAlign: "left" }}>
                {allHrefs.map((href, index) => (
                  <span className="today-links" key={index}>
                    <a target="_blank" rel="noreferrer" href={`${href}`}>
                      {href.replace(
                        /^(?:https?:\/\/)?(?:www\.)?[^]+\.(com|se|org|net|co\.uk)\//i, 
                        '$1/'
                      )}
                    </a>
                  </span>
                ))}
              </Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="green" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Messages Outside Working Hours</Card.Title>
            {isLoading ? (
              <div className="loader" />
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{outsideWorkingHoursCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="pink" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Error Count</Card.Title>
            {isLoading ? (
              <div className="loader" />
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysErrorCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
      </div>

      <div className='twoColumn-card__container'>
        <div className='card__container'>
          <Card className="blue" style={{ marginBottom: "2rem", width: "100%", height: "auto" }}>
            <Card.Body>
              <Card.Title>Top 10 Most Linked Products</Card.Title>
              {isLoading ? (
                <div className="loader" />
              ) : (
                <ul style={{ textAlign: "left", fontSize: "18px", listStyleType: "decimal", paddingLeft: "20px" }}>
                  {topProducts.length === 0 ? (
                    <li>No product links found.</li>
                  ) : (
                    topProducts.map((product, index) => (
                      <li key={index}>
                        <a href={product.link} target="_blank" rel="noreferrer">
                          {product.link}
                        </a> 
                        {' '}- {product.count} times
                      </li>
                    ))
                  )}
                </ul>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
{/* Chat Insights & Analysis Section */}
<div className='card__container'>
      <Card className="grey" style={{ marginBottom: "1rem", width: "100%", height: "auto", padding: "1rem" }}>
        <Card.Body>
          <Card.Title>Insights & Analysis</Card.Title>
          
          {openAIResult === null ? (
            <>
              {/* 
                Check if we’re currently fetching insights:
                - If isInsightsLoading is true, show the loader
                - Else, show the button
              */}
              {isInsightsLoading ? (
                <div className="loader" />
              ) : (
                <button className='btn_LoadInsights' onClick={handleLoadInsights}>
                  <BsStars /> Load AI Insights
                </button>
              )}
            </>
          ) : (
            <div style={{ fontSize: "12px" }}>
              {(() => {
                // Extract insights from openAIResult
                const insights = openAIResult.match(/\{([^}]+)\}/g);
                if (!insights) {
                  return <div>No insights found.</div>;
                }

                return insights.map((insight, index) => {
                  const cleanedInsight = insight.replace(/^{|}$/g, '').trim();
                  return (
                    <div
                      key={index}
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                        boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
                        padding: "12px 16px",
                        marginTop: "16px",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <span style={{ marginRight: "8px" }}>💡</span>
                      <span>{cleanedInsight}</span>
                    </div>
                  );
                });
              })()}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>

      <div className='chart__container'>
        <h3>Chat Activity</h3>
        {isLoading ? (
          <div className="loader" />
        ) : (
          <ChatPerHourBarChart activeClient={activeClient} />
        )}
      </div>
    </div>
  );
}

export default HomePage;