// ChatPerHourBarChart.js
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { supabase } from './supabaseClient';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ChatPerHourBarChart({ activeClient }) {
  const [chatDataByHour, setChatDataByHour] = useState(new Array(24).fill(0));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchChatData = async () => {
      const today = new Date();
      const lastWeekDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0).toISOString();
      const endDate = today.toISOString();

      try {
        const { data, error } = await supabase
          .from('chatLogs')
          .select('date')
          .eq('name', activeClient)
          .gte('date', lastWeekDate)
          .lte('date', endDate);

        if (error) throw error;

        const hourlyCounts = new Array(24).fill(0);
        data.forEach((item) => {
          const date = new Date(item.date);
          hourlyCounts[date.getHours()]++;
        });

        setChatDataByHour(hourlyCounts);
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
        setIsLoading(false);
      }
    };

    fetchChatData();
  }, [activeClient]);

  const chartData = {
    labels: Array.from({ length: 24 }, (_, i) => `${i}:00`), // Labels for each hour
    datasets: [
      {
        label: ' Amount of chats',
        data: chatDataByHour,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return isLoading ? <div className="loader" /> : <Bar data={chartData} options={options} />;
}

export default ChatPerHourBarChart;
