// Make sure you have an environment variable or some secure storage for your API key.
const API_KEY = process.env.REACT_APP_GPT_API;

export async function sendBigramsToOpenAI(bigrams) {
    const systemMessage = {
        "role": "system",
        "content": "You are a helpful assistant. You exist on a saas dashboard that analyzes a chat log from a chat bot that exist on a website, you never give feedback on how to improve the chat, improve responses only on products, seo purpose, blog posts. You must follow the user's instructions exactly. Do not mention the word 'bigram' in your answer. Do not include any other formatting or explanations beyond what the user requested."
      };
      
      const apiMessages = [
        {
          "role": "user",
          "content": `Only return answers in this format: {A new concrete insight generated}. Here are the top 10 bigrams: ${JSON.stringify(bigrams)}. Provide a short analysis describing what happened in the chats that comes from the user. Don't mention Bigram. Don't give insights on how to improve the chat bot. Give insights to act on and make them short and concrete. These insights should be wrapped inside {}. `
        }
      ];

  const apiRequestBody = {
    "model": "gpt-4o-mini",  // or your chosen model
    "messages": [
      systemMessage,
      ...apiMessages
    ]
  };

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    const result = await response.json();
   //console.log('OpenAI Response:', result);
    return result;
  } catch (error) {
    console.error('Error sending bigrams to OpenAI:', error);
    return null;
  }
}

export function getTopBigrams(allDescriptions, stopWords) {
  // Remove HTML tags
  const cleanDescriptions = allDescriptions.replace(/<[^>]*>/g, '');

  // Convert to lowercase, remove punctuation, split into words
  const words = cleanDescriptions
    .toLowerCase()
    .replace(/[^\wåäöÅÄÖéÉèÈüÜûÛöÖ'’]+/g, ' ')
    .split(/\s+/)
    .filter(word => word.length > 2 && !stopWords.has(word));

  const bigramCount = {};
  
  // Create bigrams
  for (let i = 0; i < words.length - 1; i++) {
    const bigram = words[i] + ' ' + words[i + 1];
    bigramCount[bigram] = (bigramCount[bigram] || 0) + 1;
  }

  // Sort bigrams by frequency
  const sortedBigrams = Object.entries(bigramCount).sort((a, b) => b[1] - a[1]);
  
  // Take top 10
  const topBigrams = sortedBigrams.slice(0, 10);

 // console.log("Top 10 bigrams (two-word sequences):", topBigrams);

  return topBigrams;
}
